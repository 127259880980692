import * as React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'
import Hero from '../../components/hero'
import SubHeader from '../../components/subheader'
import AdBanner from '../../components/adbanner'
import { AdContainer } from '../../components/adstyling'
import { Helmet } from "react-helmet"



// styles

const Category = styled.span`
  > h4 {
    @media only screen and (max-width: 900px) {
      margin: 0 20px;
    }
  }
`;

const WordSection = styled.div`
  max-width: 900px;
  margin: 30px auto 0;

  @media only screen and (max-width: 900px) {
    margin: 30px 20px 0;
  }

  h4 {
    color: #441600;
    margin-bottom: 0;
  }
`;

const WORDS = [
  {'letter': 'A', 'words': [
    {'word': 'Act', 'definition': <p>A section of an opera, used by the composer to divide the work into dramatic sections often with breaks for the audience in between.</p>},
    {'word': 'Aria', 'definition': <p>Literally "air" in Italian. A song for solo voice usually with instrumental accompaniment.</p>},
  ]},
  {'letter': 'B', 'words': [
    {'word': 'Banda', 'definition': <p>Musicians who perform on the stage, in addition to those in the pit. Often as military players or a small group playing in a party scene.</p>},
    {'word': 'Baritone', 'definition': <p><Link to="/operaabc/voices">The middle male singing voice</Link>, higher than a Bass but lower than a Tenor.</p>},
    {'word': 'Bass', 'definition': <p><Link to="/operaabc/voices">The lowest male singing voice.</Link></p>},
    {'word': 'Baton', 'definition': <p>The stick held by the conductor which he uses to direct the orchestra.</p>},
    {'word': 'Bel canto', 'definition': <p>The literal translation is "Beautiful singing". More specifically a type of Italian singing style epitomised by Donizetti and Rossini during the first half of the 19th Century.</p>},
    {'word': 'Bravo', 'definition': <p>An exclamation during applause when audience members are particularly impressed and enthuised. If we’re to be fussy about the Italian: Bravo should be directed at a single male performer, Brava at a single female and Bravi at a group. However, it’s largely been adopted into English as a one size fits all so you do you.</p>},
  ]},
  {'letter': 'C', 'words': [
    {'word': 'Cadenza', 'definition': <p>A designated part of the music usually found towards the end of an aria where the singer can really show off. Sometimes improvised though more commonly prepared in advance, a cadenza is rhythmically free and full of ornamentation.</p>},
    {'word': 'Castrato', 'definition': <p>An adult male singer who has kept their childhood high range by being castrated before puberty. Extremely popular, many Castrati were mega stars of their era, until the end of the 18th Century when the practice was outlawed. These roles are now largely sung by female Mezzos or male Countertenors.</p>},
    {'word': 'Choreographer', 'definition': <p>The person who creates the steps for the dances within a production.</p>},
    {'word': 'Chord', 'definition': <p>A set of two or more notes heard together.</p>},
    {'word': 'Chorus', 'definition': <p>A large group of singers who sing together, portraying unnamed characters.</p>},
    {'word': 'Claque', 'definition': <p>Thankfully now a thing of the past. A claque was a group of audience members who would cheer or boo for money, sometimes blackmailing singers into paying.</p>},
    {'word': 'Coloratura', 'definition': <p>Elaborate singing involving vocal runs, trills and leaps. One of the most virtuosic aspects of opera.</p>},
    {'word': 'Composer', 'definition': <p>Specifically, the person who writes all the music in an opera. More generally the composer usually provides the vision and drive behind an opera as well.</p>},
    {'word': 'Concert Master', 'definition': <p>The leader of the orchestra. Always a violinist who sits right at the front by the conductor. Plays the violin solos.</p>},
    {'word': 'Contralto', 'definition': <p><Link to="/operaabc/voices">The lowest female voice</Link>. True contralto roles are relatively rare in opera.</p>},
    {'word': 'Countertenor', 'definition': <p><Link to="/operaabc/voices">The highest male voice</Link>, usually singing in the range of a contralto or mezzo-soprano. Achieved in most cases through the use of falsetto.</p>},
    {'word': 'Curtain Call', 'definition': <p>After the main applause at the end of a performance, sometimes the principals and conductor will come out for a final series of bows after the curtain has fallen. These are done in front of the curtain hence the term! Less frequent than they used to be (but still wildly popular at ballet performances).</p>},
  ]},
  {'letter': 'D', 'words': [
    {'word': 'Da Capo Aria', 'definition': <p>A very specific Baroque form of aria. Written in a ternary form, ABA, with the A and B sections significantly contrasting.</p>},
    {'word': 'Director', 'definition': <p>The director is in charge of everything that happens on stage. He instructs the performers and provides the overall vision for the production. Also known as the Regisseur (in French and German houses).</p>},
    {'word': 'Dress Rehearsal', 'definition': <p>The final rehearsal in which all the production elements, including orchestra, sets and costumes, come together. The aim is to have one complete, unbroken run through before the audience comes for opening night.</p>},
    {'word': 'Duet', 'definition': <p>Music written for two performers to sing together.</p>},
    {'word': 'Dynamics', 'definition': <p>The musical term for volume.</p>},
  ]},
  {'letter': 'E', 'words': [
    {'word': 'Encore', 'definition': <p>A rare thing in opera. If a section is extremely well received by an audience they can shout "encore" which means they want to hear the section again. Rarely granted as it disrupts the flow of the opera.</p>},
    {'word': 'Eurotrash', 'definition': <p>A pejorative term for revisionist opera direction. Utterly meaningless and wildly misused, if you hear someone use it, they aren't worth listening to.</p>},
  ]},
  {'letter': 'F', 'words': [
    {'word': 'Falsetto', 'definition': <p>A specific type of male singing, using a different vocal register to sing much higher than a singer’s conventional vocal range.</p>},
    {'word': 'Finale', 'definition': <p>The final section of an Act or Opera. These are some of the few moments in an opera when many of the principals will sing together.</p>},
  ]},
  {'letter': 'G', 'words': [
    {'word': 'Gesamtkunstwerk', 'definition': <p>A total, unified work of art according to the aesthetic ideals of Richard Wagner.</p>},
  ]},
  {'letter': 'H', 'words': [
    {'word': 'Harmony', 'definition': <p>The use of multiple pitches, or chords, at the same time in music.</p>},
  ]},
  {'letter': 'I', 'words': [
    {'word': 'Impresario', 'definition': <p>The person in charge of running an opera company. The title is different around the world: "General Manager", "Intendant" and "Artistic Director" all variations you might hear.</p>},
    {'word': 'Intermission', 'definition': <p>See Interval</p>},
    {'word': 'Interval', 'definition': <p>The interval or intermission is the break period between Acts. A chance to stretch your legs and grab a glass of wine or icecream.</p>},
  ]},
  {'letter': 'L', 'words': [
    {'word': 'Leitmotif', 'definition': <p>A short recurring musical phrase associated with a particular character, theme, idea, emotion etc. This musical concept has been somewhat present throughout the history of music but is particularly associated with Wagner’s large scale operas. Very common in contemporary film scoring, e.g. Vader’s theme in Star Wars.</p>},
    {'word': 'Librettist', 'definition': <p>The person who writes the libretto.</p>},
    {'word': 'Libretto', 'definition': <p>The "little book" in Italian. The Libretto contains all the words and stage directions for an opera.</p>},
  ]},
  {'letter': 'M', 'words': [
    {'word': 'Mark', 'definition': <p>To sing softly, at less than full voice. During rehearsals singers may mark their parts to avoid straining their voices.</p>},
    {'word': 'The Met', 'definition': <p>The shorthand name for the Metropolitan Opera in New York.</p>},
    {'word': 'Mezza voce', 'definition': <p>Literally Italian for "half voice". Specified by some composers for a passage to be sung in a quiet, soft style.</p>},
    {'word': 'Mezzo-soprano', 'definition': <p><Link to="/operaabc/voices">Literally a "middle soprano"</Link>. Singing music lower than sopranos but higher than contraltos.</p>},
  ]},
  {'letter': 'O', 'words': [
    {'word': 'Opera Buffa', 'definition': <p>A "comic" opera. A popular genre of opera during the 18th Century. The characters were largely ordinary, contemporary people, the plots light and frothy.</p>},
    {'word': 'Opera Seria', 'definition': <p>A "serious" opera. A popular genre of opera during the 18th Century. The characters were largely gods and ancient heros, the plots epic and formulaic.</p>},
    {'word': 'Operetta', 'definition': <p>A type of light opera. Popular from the mid-19th Century until the 1930s these are generally shorter and funnier than conventional operas. There are many styles but most have spoken dialogue and dance sequences. Somewhat of a transitional form to modern musical theatre.</p>},
    {'word': 'Oratorio', 'definition': <p>A dramatic work for orchestra, chorus and soloists that unlike opera was principally written to be performed in concert. The genre is predominantly made up of works with serious religious themes.</p>},
    {'word': 'Orchestra', 'definition': <p>The group of musicians who perform the music of the opera. During the Baroque period they were conventionally a string and woodwind group but over the history of opera the orchestra has expanded greatly to the modern standard which includes a full complement of brass and percussion.</p>},
    {'word': 'Ornamentation', 'definition': <p>Decorative additional notes such as trills and appogiaturas that enhance the basic melody.</p>},
    {'word': 'Overture', 'definition': <p>The musical introduction to the evening. The overture frequently includes elements of the score that is to follow. The German word is Vorspiel.</p>},
  ]},
  {'letter': 'P', 'words': [
    {'word': 'Patter', 'definition': <p>A rapid flurry of singing, with many words crammed into the musical line.</p>},
    {'word': 'Pit', 'definition': <p>The sunken area between the audience and the stage where the orchestra sits.</p>},
    {'word': 'Portamento', 'definition': <p>An italian term for the vocal technique of sliding from one pitch to another continuously, rather than jumping between the two.</p>},
    {'word': 'Prompter', 'definition': <p>A person who sits in a small box at the front of the stage and gives cues to the performers. Something of a relic these days, many of the major houses no longer have a prompter, requiring the singers to actually learn their parts!</p>},
    {'word': 'Proscenium', 'definition': <p>Almost all opera houses are procenium theatres. The proscenium arch is the frame around the stage (often golden) that divides the audience from the performers.</p>},
  ]},
  {'letter': 'R', 'words': [
    {'word': 'Recitative', 'definition': <p>A halfway house between singing and speaking. Sung in a declamatory style in the rhythm of spoken word often on a single pitch. Frequently in earlier opera the narrative was largely advanced through recitative with arias being focused on expressing emotion.</p>},
    {'word': 'Répétiteur', 'definition': <p>A member of the music staff who rehearses with the singers using a piano before the later rehearsals involving the orchestra.</p>},
  ]},
  {'letter': 'S', 'words': [
    {'word': 'Singspiel', 'definition': <p>The german for "song play". It was a popular form of theatre, mixing spoken drama with song, in 18th Century Germany. Several operas were written in this genre including <i>The Magic Flute</i> by Mozart.</p>},
    {'word': 'Soprano', 'definition': <p><Link to="/operaabc/voices">The highest female voice</Link> and the highest voice in Operatic performance.</p>},
    {'word': 'Soubrette', 'definition': <p>Both a type of part and <Link to="/operaabc/voices">type of voice</Link>. These are flirty, sexy roles sung by sopranos with lighter, sweeter voices.</p>},
    {'word': 'Spinto', 'definition': <p>Literally "pushed", an adjective to describe a voice with extra weight and depth e.g a spinto tenor.</p>},
    {'word': 'Supernumerary', 'definition': <p>The operatic name for extras. "Supers" are non-singing, non-speaking performances who act smaller roles or fill out the crowd scenes.</p>},
    {'word': 'Surtitles', 'definition': <p>A translation of the libretto that is projected onto a screen above the stage, or on screens on the back of he chair in front of you. Also known as supertitles.</p>},
  ]},
  {'letter': 'T', 'words': [
    {'word': 'Tenor', 'definition': <p><Link to="/operaabc/voices">The highest natural male voice</Link>.</p>},
    {'word': 'Tessitura', 'definition': <p>The core range of a specific role. A soprano role might have a low tessitura, i.e. though it lies within the soprano range the majority of the part lies at the low end of the soprano range.</p>},
    {'word': 'Through-composed opera', 'definition': <p>Operas that consist of continuous music without breaks for recitive or spoken dialogue. Largely the standard from the 19th Century onwards (and popularised in 20th century musical theatre by Andrew Lloyd-Webber and Claude-Michel Schönberg).</p>},
    {'word': 'Toi Toi Toi', 'definition': <p>A popular operatic way of saying good luck (think 'Break A Leg' as the theatrical equivalent). The etymology of this is a bit fuzzy, possibly something to do with spitting or curses. Pronounced ‘toy toy toy’ rather than ‘twah twah twah’ as you might expect. </p>},
    {'word': 'Trouser role', 'definition': <p>Also known as a pants part, these are male roles for female singers. Came to prominence in the 19th Century with the abandonment of Castrati.</p>},
  ]},
  {'letter': 'V', 'words': [
    {'word': 'Verismo', 'definition': <p>Literally, "Truth". A genre of opera from the late 19th Century. These are operas that depict everyday people in realistic trials and tribulations (though they still mostly involve love and death!)</p>},
    {'word': 'Vibrato', 'definition': <p>The oscillating quality that is present to some degree in all operatic voices. Used variously to support the voice, maintain pitch and for stylistic effect.</p>},
    {'word': 'Vocal Range', 'definition': <p>A singer's vocal range defines the highest and lowest notes that they can sing.</p>},
  ]},
];


// markup
const RunningPage = () => {
  const [word, setWord] = React.useState('');
  const [foundWords, setFoundWords] = React.useState(WORDS);

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = WORDS.filter((word) => {
        return true;
      });
      setFoundWords(results);
    } else {
      setFoundWords(WORDS);
    }

    setWord(keyword);
  };

  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Operatic Glossary | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operaabc/glossary" />
          <meta name="description" content="Operatic terminology can be confusing. Here's a handy glossary for a range of opera and theatre words, terms and descriptors." />
        </Helmet>
        <Hero title="Opera Glossary" subtitle="Defining operatic terminology"/>

        {foundWords.map((d, index) => (
          <Category id={d.letter}>
            <SubHeader title={d.letter}/>
            {d.words.map(w => (
              <WordSection id={w.word}>
                <h4>{w.word}</h4>
                {w.definition}
              </WordSection>
            ))}
            {(index+2) % 3 == 0 &&
              <AdContainer>
                <AdBanner
                  style={{ display: 'block' }}
                  slot="3461570696"
                  format="auto"
                  responsive="true"
                />

        
                <div id="ezoic-pub-ad-placeholder-103"> </div>
      
              </AdContainer>
            }
          </Category>
        ))}

    </Layout>
  )
}

export default RunningPage
